<template>
  <div class="contenedor-indicador">
    <Spinner 
      :mostrarSpinner="spinner"
    />

    <!-- Navegqación -> Titulo y boton <- -->
    <div class="indicador-head" v-if="mostrarBoton">
      <div class="indicador-body">
        <h1>Junta de Coordinación</h1>
      </div>
      <BotonAdd 
        @on:click="mostrarMenu"
      />
    </div>
    
    <!-- Contenedor indicadores resumén -->
    <div 
      class="indicador-data"
      v-if="indicadorData"
      >

        <div class="ficha-data">
          <div class="header-data">
            <p>Periodo: 2021</p>
            <div class="distritos-select">
              <select name="distritos-select" id="distritosSelect">
                <option v-for="(distrito, index) in distritos" :key="index"
                  :value="distrito.value"
                  >
                  {{distrito.nombre}}
                  </option>
              </select>
            </div>
            <button class="btn btn-primary">Consultar</button>
          </div>
           <div class="body-data">
              <p>Hombres: </p>
              <p>Mujeres: </p>
              <h4>Total: </h4>
            </div>
          </div>


    </div>


  <!-- Contenedor para la captura de información -->
    <div 
      class="agregar-indicador"
      v-if="indicadorFormulario"
      >

        <p @click="cerrarRegistro">X</p>
        <label for="dua_hombres">Hombres</label>
        <input type="number" id="dua_hombres" v-model="diputaciones.hombres">

        <label for="dua_mujeres">Mujeres</label>
        <input type="number" id="dua_mujeres" v-model="diputaciones.mujeres">
    </div>
      
  </div>

</template>
<script>


import { defineAsyncComponent } from 'vue'

export default {

  components: {
    BotonAdd: defineAsyncComponent( () => import('../../../components/BotonAdd.vue')),
    Spinner: defineAsyncComponent( () => import('../../../components/Spinner.vue'))
  },
  data() {
    return {
      indicadorData: false,
      indicadorFormulario: false,
      diputaciones: {
        hombres: null,
        mujeres: null,
        isLoading: true,
      },
      distritos: [
        {nombre: 'Distrito 1',value:'1'},
        {nombre: 'Distrito 2',value:'2'},
        {nombre: 'Distrito 3',value:'3'},
        {nombre: 'Distrito 4',value:'4'},
        {nombre: 'Distrito 5',value:'5'},
        {nombre: 'Distrito 6',value:'6'},
        {nombre: 'Distrito 7',value:'7'},
        {nombre: 'Distrito 8',value:'8'},
        {nombre: 'Distrito 9',value:'9'},
        {nombre: 'Distrito 10',value:'10'},
        {nombre: 'Distrito 11',value:'11'},
        {nombre: 'Distrito 12',value:'11'}
        
      ],
      spinner: true,
      mostrarBoton: false

    }
  },
  methods: {
    mostrarMenu(){
      this.indicadorData = false
      this.indicadorFormulario = true
    },
    stopSpinner(){

      setTimeout(() => {
        this.spinner = false
        this.mostrarBoton = true
        this.indicadorData = true 

      },3000);
    },
    cerrarRegistro(){
      this.indicadorFormulario = false
      this.indicadorData = true
    }
  },
  created(){
    this.stopSpinner()
  }

}
</script>

<style lang="scss">
  .ficha-data{
    width:80%;
    margin:20px auto;
    height:200px;
    background-color: #f1f1f1;
    .header-data{
      padding: 12px 12px;
      display:flex; align-items:center; justify-content: space-between;
    }
    .body-data{
      display: flex;  
    }
  }
</style>